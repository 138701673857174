import React from "react";
// Customizable Area Start
import {
    Grid,
    Paper,
    Typography,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow, 
    TextField, Dialog, 
    DialogActions, DialogContent,
    DialogTitle, Button
} from "@material-ui/core"
import { DataGrid } from '@material-ui/data-grid';
import { StyleRules, withStyles } from '@material-ui/core/styles';
// Customizable Area End

import PlaylistController, {
    Props,
    configJSON,
    IPlaylistcollection
} from "./PlaylistController";

export class Playlist extends PlaylistController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { allPlaylistDataWeb, allSongsColumnInfo, allSonglistDataWeb, playlistDialog, playlistDialogMode, page, rowsPerPage, selectedSongIdsWeb } = this.state;
        return (
            //Merge Engine DefaultContainer
            <div className={classes.root}>
                {/* Customizable Area Start */}
                {/* Merge Engine UI Engine Code */}
                <div className={classes.header}>
                    <Typography variant="h4" gutterBottom>
                        Playlist
                    </Typography>
                    <Button data-test-id="createPlayList" variant="contained" color="primary" onClick={() => this.handlePlaylistClickOpenWeb('add')}>
                        Create New Playlist
                    </Button>
                </div>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Logo</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? allPlaylistDataWeb.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : allPlaylistDataWeb
                            ).map((rowData: IPlaylistcollection) => (
                                <TableRow key={rowData.id}>
                                    <TableCell component="th" scope="rowData">
                                        <img src={rowData.attributes?.image} width={25} height={25} className={classes.playlistLogo} />
                                    </TableCell>
                                    <TableCell>{rowData.attributes?.title}</TableCell>
                                    <TableCell>{rowData.attributes?.description}</TableCell>
                                    <TableCell style={{ display: "inline" }}>
                                        <div className={classes.tableActionRow}>
                                            <Button variant="contained" data-test-id="viewButton" color="primary" onClick={() => this.handleViewPlayList('view', rowData)}>
                                                {configJSON.viewText}
                                            </Button>
                                            <Button variant="contained" data-test-id="deleteButton" color="primary" onClick={() => this.handleDeletePlayList(rowData)}>
                                                {configJSON.deleteText}

                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog data-test-id="closeDailog" open={playlistDialog} maxWidth={'md'} fullWidth={true} onClose={this.handlePlaylistClickCloseWeb} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        {playlistDialogMode === 'add' && 'Add Playlist'}
                        {playlistDialogMode === 'view' && configJSON.viewPlayListBtn}
                        {playlistDialogMode === "edit" && configJSON.editPlayListBtn}
                    </DialogTitle>
                    <DialogContent>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        placeholder="Playlist Name"
                                        type="text"
                                        value={this.state.playlistName}
                                        data-test-id="playlistNameInput"
                                        onChange={this.handlePlaylistName}
                                    />
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        minRows={3}
                                        type="text"
                                        placeholder="Description"
                                        fullWidth
                                        data-test-id="descriptionInput"
                                        value={this.state.description}
                                        onChange={this.handleDescriptionWeb}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} />

                                <Grid item xs={12} md={4}>
                                    {this.state.playlistDialogMode === "view" ?
                                        <img src={this.state.viewImageUrl} width={"80px"} height={"80px"} />
                                        : <input
                                            type="file"
                                            accept="image/png,image/jpeg"
                                            onChange={this.handleFiles}
                                            data-test-id="userPlayListLogo"
                                        />}

                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.datagrid}>
                            {this.state.playlistDialogMode === "view" ? <DataGrid
                                rows={this.state.savedSongsData}
                                columns={this.state.viewPlayListSongsColumns}
                                pageSize={5}
                                selectionModel={selectedSongIdsWeb}
                                data-test-id="selectSongs"
                                onSelectionModelChange={selectedModel => { this.selectedSongsWeb([]) }}
                            />
                                : <DataGrid
                                    rows={allSonglistDataWeb}
                                    columns={allSongsColumnInfo}
                                    pageSize={5}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    selectionModel={selectedSongIdsWeb}
                                    data-test-id="selectSongs"
                                    onSelectionModelChange={(selectionModel) => this.selectedSongsWeb(selectionModel)}
                                />}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button data-test-id="cancelplaylist" onClick={this.handlePlaylistClickCloseWeb} color="primary">
                            {configJSON.cancelBtn}
                        </Button>
                        {playlistDialogMode === 'view' ?
                            <Button data-test-id="editPlaylist" color="primary" onClick={() => this.editPlaylist("edit")}>Edit playlist</Button> :
                            <Button data-test-id="updatePlaylist" color="primary" onClick={this.UpdatePlaylistDataWeb}>
                                {playlistDialogMode === 'edit' && configJSON.updatePlayListBtn}

                            </Button>}
                        <Button data-test-id="createPlaylist" onClick={this.savePlaylist} color="primary" disabled={this.state.playlistName != '' ? false : true}>
                            {playlistDialogMode === 'add' && configJSON.savePlayListBtn}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Merge Engine UI Engine Code */}
                {/* Customizable Area End */}
            </div>
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
const styles: StyleRules = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '15px 0px'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between",
        width: "650px",
    },
    table: {
        width: '650px'
    },
    tableActionRow: {
        '& > *': {
            marginRight: '8px'
        }
    },
    datagrid: {
        height: 400,
        width: '100%',
        marginTop: '15px'
    },
    playlistLogo: {
        width: "30px",
        height: "30px"
    }
};
export default withStyles(styles)(Playlist);
// Customizable Area End